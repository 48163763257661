
import { defineComponent, reactive, toRefs, ref } from 'vue';
import { getUserInfo, saveHunterCompany, listDict, listCity } from '@/util/apis'
import { ElLoading, ElMessage,ElMessageBox } from 'element-plus'
import router from "@/router/index";
export default defineComponent({
    name: '',
    setup() {
        var ruleFormRef = ref(null);

        var loading: { close: () => void; } | null = null;
        
        const state = reactive({
            cIFrom: {
                cName: '',
                csName: '',
                cLogo: '',
                cLogoUrl: '',
                cHy: '' as any,
                cCity: '' as any,
                cGm: '' as any,
                cXz: '' as any,
                cFzjd: '' as any,
                cYyzz: '',
                cYyzzUrl: '',
                cDescribe: '',
                address: ''
            },
            cIRule: {
                cName: [{ required: true, message: '请填写公司全称', trigger: 'change' }],
                csName: [{ required: true, message: '请填写公司简称', trigger: 'change' }],
                cLogo: [{ required: true, message: '请上传公司Logo', trigger: 'change' }],
                cHy: [{ required: true, message: '请选择公司行业', trigger: 'change' }],
                cCity: [{ required: true, message: '请选择所在城市', trigger: 'change' }],
                cGm: [{ required: true, message: '请选择公司规模', trigger: 'change' }],
                cXz: [{ required: true, message: '请选择公司性质', trigger: 'change' }],
                cFzjd: [{ required: true, message: '请选择发展阶段', trigger: 'change' }],
                cYyzz: [{ required: true, message: '请上传公司营业执照', trigger: 'change' }],
                cDescribe: [{ required: true, message: '请填写公司介绍', trigger: 'change' }],
                address: [{ required: true, message: '请填写公司详细地址', trigger: 'change' }],
            },
            uploadUrl: process.env.VUE_APP_API_URL + '/sys/file/upload.do',
            kwOp: [{
                value: 'HTML',
                label: 'HTML',
            },
            {
                value: 'CSS',
                label: 'CSS',
            }],
            categoryOp: [] as any,

            cXz: [] as any,
            cGm: [] as any,
            cFzjd: [] as any,
            cHy: [] as any,
            disabledLog: false,
            userInfo: {} as any
        })
        loading =
            ElLoading.service({
                lock: true,
                text: '加载中...',
                background: 'rgba(0, 0, 0, 0.7)',
            })
        var doArr = ['company_nature', 'company_scale', 'company_stage', 'company_industry'].map(item => listDict({ type: item }));
        var gUserInfo = {} as any;
        Promise.all([...doArr, listCity({})]).then(resList => {
            console.log('resList==', resList)
            //公司性质、公司规模、发展阶段、公司行业
            state.cXz = resList[0].data;
            state.cGm = resList[1].data;
            state.cFzjd = resList[2].data;
            state.cHy = resList[3].data;
            state.categoryOp = resList[4].data;
            getUI();
            loading!.close()
        })

        const getUI = () => {
            getUserInfo({}).then(res => {
                gUserInfo = res.data;
                state.userInfo = res.data;
                if (gUserInfo.companyInfo) {
                    state.cIFrom.cName = gUserInfo.companyInfo.name;
                    state.cIFrom.csName = gUserInfo.companyInfo.simpleName;
                    state.cIFrom.cHy = gUserInfo.companyInfo.industry;
                    state.cIFrom.cCity = [gUserInfo.companyInfo.addressCode.substring(0, 2) + '0000', gUserInfo.companyInfo.addressCode.substring(0, 4) + '00', gUserInfo.companyInfo.addressCode];
                    state.cIFrom.cGm = gUserInfo.companyInfo.scale;
                    state.cIFrom.cXz = gUserInfo.companyInfo.nature;
                    state.cIFrom.cFzjd = gUserInfo.companyInfo.stage;
                    state.cIFrom.cYyzz = gUserInfo.companyInfo.license;
                    state.cIFrom.cYyzzUrl = gUserInfo.companyInfo.ext.license;
                    state.cIFrom.cDescribe = gUserInfo.companyInfo.description;
                    state.cIFrom.address = gUserInfo.companyInfo.address;
                    state.disabledLog = gUserInfo.companyInfo.state==0?true:false;
                    state.cIFrom.cLogo = gUserInfo.companyInfo.ext.logo;
                    state.cIFrom.cLogoUrl = gUserInfo.userInfo.photo;
                }
            })
        }

        const methods = {
            handleUpImage(res: any) {
                state.cIFrom.cLogo = res.data.id;
                state.cIFrom.cLogoUrl = res.data.ext.fullUrl;
            },
            handleUpImageYyzz(res: any) {
                state.cIFrom.cYyzz = res.data.id;
                state.cIFrom.cYyzzUrl = res.data.ext.fullUrl;
            },
            async comit(formEl: any) {
                if (!formEl) return
                await formEl.validate((valid: any, fields: any) => {
                    if (valid) {
                        loading =
                            ElLoading.service({
                                lock: true,
                                text: '提交资料中...',
                                background: 'rgba(0, 0, 0, 0.7)',
                            })
                        saveHunterCompany({
                            id: (state.userInfo.companyInfo&&state.userInfo.companyInfo.id)?state.userInfo.companyInfo.id:'',
                            name: state.cIFrom.cName,
                            simpleName: state.cIFrom.csName,
                            license: state.cIFrom.cYyzz, //营业执照
                            description: state.cIFrom.cDescribe,//公司介绍
                            nature: state.cIFrom.cXz, //性质
                            scale: state.cIFrom.cGm,//规模
                            industry: state.cIFrom.cHy,//行业
                            addressCode: state.cIFrom.cCity[2],//地址编码
                            address: state.cIFrom.address, //详细地址
                            stage: state.cIFrom.cFzjd, //发展阶段
                            logo: state.cIFrom.cLogo
                        }).then(res => {
                            loading!.close()
                            if (res.code == 0) {
                                getUI();
                                open();
                                // router.push({ path: "/companyInfo" });
                            }
                        }).catch(f => {
                            loading!.close()
                        })
                    }
                })
            },

        }

        // const open = () => {
        //     ElMessageBox.alert('This is a message', 'Title', {
        //         // if you want to disable its autofocus
        //         // autofocus: false,
        //         confirmButtonText: 'OK',
        //         callback: (action: Action) => {
        //         ElMessage({
        //             type: 'info',
        //             message: `action: ${action}`,
        //         })
        //         },
        //     })
        //  }

        return {
            ...toRefs(state), ...toRefs(methods), ruleFormRef
        }
    }
})
